import { NgModule } from '@angular/core';
import { MainGuard } from './guards/main.guard';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {NotfoundComponent} from './components/notfound/notfound.component';
import {MainComponent} from './components/main/main.component';
import {LogoutComponent} from './components/logout/logout.component';
import {TablaNormasComponent} from './components/tabla-normas/tabla-normas.component'
import { NuevaPublicacionComponent } from './components/nueva-publicacion/nueva-publicacion.component';
import { TipoNormaLegalComponent } from './components/tipo-norma-legal/tipo-norma-legal.component';
import { VozNormaLegalComponent } from './components/voz-norma-legal/voz-norma-legal.component';
import { UoComponent } from './components/uo/uo.component';
import { AfectacionNormaLegalComponent } from './components/afectacion-norma-legal/afectacion-norma-legal.component';
import { OficinaComponent } from './components/oficina/oficina.component';
import { OficinaTipoNormaLegalComponent } from './components/oficina-tipo-norma-legal/oficina-tipo-norma-legal.component';
import { OficinaNotifComponent } from './components/oficina-notif/oficina-notif.component';

const routes: Routes = [
  { path: 'notfound', component: NotfoundComponent },
  { path: 'logout', component: LogoutComponent },
  { path: '', component: HomeComponent, children:
    [ { path: 'main', component: MainComponent, canActivate: [MainGuard] },
      { path: 'normas', component: TablaNormasComponent, canActivate: [MainGuard] },
      { path: 'nueva', component: NuevaPublicacionComponent, canActivate: [MainGuard] },
      { path: 'tipo-norma-legal', component: TipoNormaLegalComponent, canActivate: [MainGuard] },
      { path: 'voz-norma-legal', component: VozNormaLegalComponent, canActivate: [MainGuard] },
      { path: 'uo', component: UoComponent, canActivate: [MainGuard] },
      { path: 'afectacion-norma-legal', component: AfectacionNormaLegalComponent, canActivate: [MainGuard] },
      { path: 'oficina', component: OficinaComponent, canActivate: [MainGuard] },
      { path: 'oficina-tipo-norma-legal', component: OficinaTipoNormaLegalComponent, canActivate: [MainGuard] },
      { path: 'oficina-notif', component: OficinaNotifComponent, canActivate: [MainGuard] }
    ] } ,
  // { path: '**', component: NotfoundComponent } 
  { path: '**', component: HomeComponent }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
 