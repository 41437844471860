import { Component, OnInit } from '@angular/core';
import { AfectacionNormaLegal } from '../../models/afectacion-norma-legal'
import { MessageService } from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import { NormaService } from '../../services/norma.service'
import { CrudsService } from '../../services/cruds.service'

@Component({
  selector: 'app-uo',
  templateUrl: './afectacion-norma-legal.component.html',
  styleUrls: ['./afectacion-norma-legal.component.scss']
})
export class AfectacionNormaLegalComponent implements OnInit {  

  caso_uso: string = 'new'
  btn_aceptar_lbl: string = ''
  btn_aceptar_style: string = ''
  mensaje_eliminacion: string = '¿Confirma la elminación de esta Afectación de Norma Legal?'

  id_afectacion_norma_legal: string = ''
  it_descripcion: string = ''
  it_descripcion_disabled: boolean = false
  tb_destacado: boolean = true
  tb_destacado_disabled: boolean = false
  it_destacado_texto: string = ''
  it_destacado_texto_disabled: boolean = false
  it_buscar: string = ''

  tbl_columnas = []
  tbl_afectacion_norma_legal: AfectacionNormaLegal[] = []
  tbl_afectacion_norma_legal_selected: AfectacionNormaLegal = null
  tbl_totalRecords: number = 0
  tbl_paginatorPosition: string = "bottom"
  tbl_loading: boolean = false;

  constructor(
    private normaService: NormaService,
    private messageService: MessageService,
    private crudsService: CrudsService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {

    this.tbl_loading = true

    this.tbl_columnas = [
      { field: 'descripcion', header: 'Descripción' },
      { field: 'destacado', header: 'Destacar' },
      { field: 'destacado_texto', header: 'Texto para destacar' }
    ]

    this.clear_components()
    this.load_data()
  }

  cancelar() {
    this.clear_components()
  }

  aceptar() {

    let datos_ok: boolean = true

    if (this.it_descripcion == '') {
      // Si el usuario no escribe en descripcion  

      this.messageService.add({severity:'warn', summary:'', detail:'Debe indicar la descripción de la Afectación de Norma Legal'})
      datos_ok = false
    }

    if (datos_ok) {
      // Si datos ok

      let object: any = {}
      let caso_uso_mensaje: string

      if (this.caso_uso == 'new') {
        object['descripcion'] = this.it_descripcion
        object['destacado'] = this.tb_destacado
        object['destacado_texto'] = this.it_destacado_texto
        caso_uso_mensaje = 'agregada'
        this.guardar(object, caso_uso_mensaje)
      } else if (this.caso_uso == 'edit') {
        object['id'] = this.id_afectacion_norma_legal
        object['descripcion'] = this.it_descripcion
        object['destacado'] = this.tb_destacado
        object['destacado_texto'] = this.it_destacado_texto
        caso_uso_mensaje = 'actualizada'
        this.guardar(object, caso_uso_mensaje)
      } else if (this.caso_uso == 'delete') {
        this.confirmationService.confirm({
          header: "Atención!",
          message: this.mensaje_eliminacion,
          acceptLabel: 'Sí',
          rejectLabel: 'No',
          accept: () => {
            object['id'] = this.id_afectacion_norma_legal
            caso_uso_mensaje = 'eliminada'
            this.guardar(object, caso_uso_mensaje)
          }
        })
      }
    }
  }

  guardar(par_object, par_caso_uso_mensaje) {

    this.crudsService.afectacionNormaLegal(this.caso_uso, par_object).subscribe(data => {

      if (data['code'] != undefined) {
        // Si se guardo ok

        this.messageService.add({severity:'success', summary:'', detail: 'Afectación de Norma Legal ' + par_caso_uso_mensaje})
        this.clear_components()
        this.load_data(this.it_buscar)
      } else {
        // Si no se guardo ok

        if (data['error_code'] != undefined) {
          if (data['message_code'] == 'err-afectacion-nl-already-exist') {
            this.messageService.add({severity:'warn', summary:'', detail: 'Ya se ha registrado esta Afectación de Norma Legal'})
          } else {
            this.messageService.add({severity:'warn', summary:'', detail: data['error_code'] + ' - ' + data['error_description'] + ' - ' + data['message_code'] + ' - ' + data['message_description']})
          }
        }
      }
    })
  }

  load_data(par_buscar?: string) {

    this.normaService.getAfectaciones(null, par_buscar).subscribe(data => {

      let list = data['data']

      if (list.length > 0) {

        for (let i=0; i<list.length; i++) {

          list[i]['destacado'] = list[i]['destacado'] ? 'Sí' : ''
        }
      }
      this.tbl_totalRecords = list.length
      this.tbl_afectacion_norma_legal = list
      this.tbl_loading = false
    })
  }

  clear_components() {

    this.caso_uso = 'new'
    this.id_afectacion_norma_legal = ''
    this.it_descripcion = ''
    this.it_descripcion_disabled = false
    this.tb_destacado = false
    this.tb_destacado_disabled = false
    this.it_destacado_texto = ''
    this.it_destacado_texto_disabled = true
    this.btn_aceptar_lbl = 'Agregar'
    this.btn_aceptar_style = 'ui-button-primary'
  }

  edit_row(par_rowData) {

    this.caso_uso = 'edit'
    this.btn_aceptar_lbl = 'Actualizar'
    this.btn_aceptar_style = 'ui-button-primary'
    this.id_afectacion_norma_legal = par_rowData.id
    this.it_descripcion = par_rowData.descripcion
    this.it_descripcion_disabled = false
    this.tb_destacado = par_rowData.destacado == 'Sí' ? true : false
    this.tb_destacado_disabled = false
    this.it_destacado_texto = par_rowData.destacado_texto
    this.it_destacado_texto_disabled = this.tb_destacado ? false : true
  }

  delete_row(par_rowData) {

    this.caso_uso = 'delete'
    this.btn_aceptar_lbl = 'Eliminar'
    this.btn_aceptar_style = 'ui-button-danger'
    this.id_afectacion_norma_legal = par_rowData.id
    this.it_descripcion = par_rowData.descripcion
    this.it_descripcion_disabled = true
    this.tb_destacado = par_rowData.destacado == 'Sí' ? true : false
    this.tb_destacado_disabled = true
    this.it_destacado_texto = par_rowData.destacado_texto
    this.it_destacado_texto_disabled = true
  }

  buscar() {

    this.load_data(this.it_buscar)
  }

  changeDestacarEstado() {

    if (this.tb_destacado) {
      // si destacar estado

      this.it_destacado_texto_disabled = false
    } else {
      // si no destacar estado

      this.it_destacado_texto = ''
      this.it_destacado_texto_disabled = true
    }
  }

  estilo_columnas(par_tipo: string, par_columna: string) {
    let estilo : string = ''

    if (par_columna == 'descripcion') {
      estilo = par_tipo == 'head' ? 'descripcion_head_style' : 'descripcion_style'
    } else if (par_columna == 'destacado') {
      estilo = par_tipo == 'head' ? 'destacado_head_style' : 'destacado_style'
    } else if (par_columna == 'destacado_texto') {
      estilo = par_tipo == 'head' ? 'destacado_texto_head_style' : 'destacado_texto_style'
    }
    return estilo
  }
}
